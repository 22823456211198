
.PriceCard__container{
    margin-left: 1em;
    margin-right: 1em;
  }
      .PriceCard__card-container {
          /* border: 2px solid red; */
          display: inline-flex;
          padding: 1.875rem 2.8125rem;
          flex-direction: column;
          justify-content: center;
          align-items: center;
          margin-top: 1.4em;
          /* gap: 1.25rem; */
          border-radius: 1.25rem;
          background: #FFF;
          box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
      }
          .PriceCard__card-title h1{
            color: var(--secundary-color);
            text-align: center;
            font-family: Roboto Mono;
            font-size: 3rem;
            font-style: normal;
            font-weight: 700;
            line-height: 1.05rem; /* 35% */
            text-transform: uppercase;
              /* border: 2px solid red; */
          }
          .PriceCard__card-trial-info{
            /* border: 2px solid red; */
            margin-top: 2em;
          }
            .PriceCard__card-trial-info p{
                color: #000;
                text-align: center;
                font-family: Lato;
                font-size: 1.5rem;
                font-style: normal;
                font-weight: 400;
                line-height: normal;
            }
    
          .PriceCard__card-price{
              margin-top: 3em;
              /* border: 3px solid red; */
  
          }
              .PriceCard__dolar-price{
                  color: #cbcbcb;
                  text-align: right;
                  font-family: Montserrat;
                  font-size: 4.5rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 1.05rem; /* 17.5% */
                  text-transform: uppercase;
                  text-decoration:line-through;
                  /* border: 2px solid black; */
              }
              .PriceCard__cents-price{
                  color: #cbcbcb;
                  font-family: Montserrat;
                  font-size: 2rem;
                  font-style: normal;
                  font-weight: 700;
                  line-height: 1.05rem; /* 46.667% */
                  text-transform: uppercase;
                  /* border: 2px solid red; */
                  margin-bottom: 3em;
                  text-decoration:line-through;
              
              }
  
  
          .PriceCard__card-description{
              /* border: 3px solid yellow; */
              margin-top: 1.5em;
              margin-bottom: 1.5em;
          }
              .PriceCard__card-description p{
                color: #000;
                text-align: center;
                font-family: 'Lato', sans-serif;
                font-size: 1.6rem;
                font-style: normal;
                font-weight: 300;
                line-height: normal;
                  /* border: 2px solid purple; */
              }
  
  
          .PriceCard__button-container{
              display: flex;
              flex-direction: row;
              justify-content: center;
              /* border: 2px solid green; */
              width: 80%;
          }
              .PriceCard__button{
                  width: 100%;
                  /* border: 2px solid red; */
               

              }
                  .PriceCard__button button{
                      width: 100%;
                      background-color: rgba(255, 255, 255, 1);
                      border: 1px solid var(--tertiary-color);
                      border-radius: 1rem;
                      padding: .5rem  0.3125rem;
                      text-align: center;
                      font-family: "Montserrat", sans-serif;
                      font-size: 1.1rem;
                      font-style: normal;
                      font-weight: 700;
                      line-height: 1.5rem;
                      text-transform: uppercase;
                      color: var(--tertiary-color);
                     cursor: pointer;
                    }
                  .PriceCard__button button:hover{
                      width: 100%;
                      background-color: var(--tertiary-color);
                      color:#ffffff;
                      border-radius: 1rem;
                      padding: .5rem  0.3125rem;
                      text-align: center;
                      font-family: "Montserrat",sans-serif;
                      font-size: 1.1rem;
                      font-style: normal;
                      font-weight: 600;
                      line-height: 1.5rem;
                      text-transform: uppercase;
                  }
  
                  .PriceCard__subscription-definition-container{
             
                      padding: 0;
                      margin: 0;
                      max-width: 22em;
                  
                  }
                      .PriceCard__subscription-definition-container p{
                              margin-top: .4em;
                              color: #00030A;
                              font-family: Lato;
                              font-size: 1rem;
                              font-style: normal;
                              font-weight: 300;
                              line-height: 2.25rem; 
                      }
  
  
  
  
  /*=========== RESPONSIVE ============*/
  
  @media (max-width: 575.98px) {
  
      .PriceCard__container{
          padding: 0;
          margin: 0;
        }
        .PriceCard__card-title h1{
            line-height: 1em;
        }
  }
  
  
  @media (min-width: 576px) and (max-width: 767.98px) {
      .PriceCard__container{
          padding: 0;
          margin: 0;
          display: flex;
          flex-direction: row;
          justify-content: space-between;
        }
      .PriceCard__container{
          /* border: 2px solid red; */
          margin-right: 1.5em;
          margin-left: 1.5em;
          /* border: 6px solid yellow; */
          height: auto;
      }
          .PriceCard__card-container{
              /* border: 2px solid blue; */
              width: auto;
              height: auto;
              
          }
  }
  
  
  @media (min-width: 768px) and (max-width: 991.98px) {
    .PriceCard__card-title h1{
        line-height: 2.5rem;
    }
  }
  
  @media (min-width: 992px) and (max-width: 1199.98px) {
  
  }
  
  
  @media (min-width: 1200px) {
  
  }