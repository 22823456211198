.ContactCard__information-container{
    display: flex;
    flex-direction: row;
    justify-content: center;
    border-radius: 0.625rem;
    width: 70%;
    padding: 1em;
    background: rgba(255, 255, 255, 0.60);
    box-shadow: 0px 4px 4px 0px rgba(0, 0, 0, 0.25);
    /* border: 3px solid red; */
}
    .ContactCard__contact-information{
        /* border: 2px solid red; */
        display: flex;
        flex-direction: column;
        justify-content: flex-start;
    }
        .ContactCard__service-number-contact-container{
            /* border: 3px solid green; */
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
        }
            .ContactCard__person-support-icon-container{
                /* border: 2px solid green; */
                margin-right: 1em;
            }
            .ContactCard__service-number-container{
              /* border: 3px solid black; */
            }
            .ContactCard__service-number-container p{
                color: var(--quaternary-color);
                font-family: Montserrat;
                font-size: 1.8rem;
                font-style: normal;
                font-weight: 700;
                line-height: 4.375rem; 
                letter-spacing: -0.09219rem;
            
            }

        .ContactCard__email-contact-container{
            /* border: 3px solid green; */
            display: flex;
            flex-direction: row;
            justify-content: flex-start;
            align-content: center;
        }
            .ContactCard__email-icon-container{
                /* border: 2px solid green; */
                margin-right: 1em;
            }
            .ContactCard__email-container{
                /* border: 3px solid black; */
                
            }
                .ContactCard__email-container p{
                    color: var(--quaternary-color);
                    font-family: Montserrat;
                    font-size: 1.8rem;
                    font-style: normal;
                    font-weight: 700;
                    line-height: 4.375rem; 
                    letter-spacing: -0.09219rem;
                }

    .ContactCard__description-information{
        /* border: 2px solid green; */
        display: flex;
        flex-direction: column;
        justify-content: center;
        margin-left: 2.5em;
    }
        .ContactCard__description-information p {
            color: #00030A;
            font-family: 'Lato', sans-serif;
            font-size: 1.5rem;
            font-style: normal;
            font-weight: 300;
            line-height: 1.9rem; 
            text-align: center;
        }



/*============== RESPONSIVE ===========*/
@media (max-width: 470.98px){
    .ContactCard__service-number-contact-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ContactCard__email-contact-container{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
    .ContactCard__information-container{
        /* border: 3px solid red; */
    }
    .ContactCard__service-number-container p{
        font-size: .95rem !important;
    }
    .ContactCard__email-container p{
        font-size: .95rem !important;
    }
}

@media (max-width: 575.98px) { 
   
    .ContactCard__information-container{
        /* border: 6px solid green; */
        width: 85%;
        flex-direction: column;
        margin-bottom: 3em;
       
    }
        .ContactCard__contact-information{
            /* border: 2px solid red; */
            width: auto;
           
        }
            .ContactCard__service-number-contact-container{
                /* border: 3px solid green; */
              
            }
                .ContactCard__person-support-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .ContactCard__service-number-container{
                  /* border: 3px solid black; */
                }
                .ContactCard__service-number-container p{
                    font-size: 1.2rem;
                }
    
            .ContactCard__email-contact-container{
                /* border: 3px solid green; */
               
            }
                .ContactCard__email-icon-container{
                    /* border: 2px solid green;
                    */
                }
                .ContactCard__email-container{
                    /* border: 3px solid black; */
                    
                }
                    .ContactCard__email-container p{
                        font-size: 1.2rem;
                   
                    }
    
        .ContactCard__description-information{
            /* border: 2px solid green; */
            margin: 0;
        
        }
            .ContactCard__description-information p {
                /* border: 2px solid red; */
                text-align: center;
            }
}


@media (min-width: 576px) and (max-width: 767.98px) {
    .ContactCard__information-container{
        /* border: 6px solid rgb(0, 0, 0); */
        width: 80%;
        flex-direction: column;
        margin-bottom: 3em;
    }
        .ContactCard__contact-information{
            /* border: 2px solid red; */
            width: auto;
           
        }
            .ContactCard__service-number-contact-container{
                /* border: 3px solid green; */
              
            }
                .ContactCard__person-support-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .ContactCard__service-number-container{
                  /* border: 3px solid black; */
                }
                .ContactCard__service-number-container p{
                    font-size: 1.3rem;
                }
    
            .ContactCard__email-contact-container{
                /* border: 3px solid green; */
               
            }
                .ContactCard__email-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .ContactCard__email-container{
                    /* border: 3px solid black; */
                    
                }
                    .ContactCard__email-container p{
                        font-size: 1.3rem;
                   
                    }
    
        .ContactCard__description-information{
            /* border: 2px solid green; */
            margin: 0;
        
        }
            .ContactCard__description-information p {
                /* border: 2px solid red; */
                text-align: center;
            }
 }


@media (min-width: 768px) and (max-width: 991.98px) {
    .ContactCard__information-container{
        /* border: 6px solid rgb(0, 0, 0); */
        width: 75%;
        flex-direction: column;
        margin-bottom: 3em;
    }
        .ContactCard__contact-information{
            /* border: 2px solid red; */
            width: auto;
           
        }
            .ContactCard__service-number-contact-container{
                /* border: 3px solid green; */
              
            }
                .ContactCard__person-support-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .ContactCard__service-number-container{
                  /* border: 3px solid black; */
                }
                /* .ContactCard__service-number-container p{
                    font-size: 1rem;
                } */
    
            .ContactCard__email-contact-container{
                /* border: 3px solid green; */
               
            }
                .ContactCard__email-icon-container{
                    /* border: 2px solid green; */
                   
                }
                .ContactCard__email-container{
                    /* border: 3px solid black; */
                    
                }
                    /* .ContactCard__email-container p{
                        font-size: 1rem;
                   
                    } */
    
        .ContactCard__description-information{
            /* border: 2px solid green; */
            margin: 0;
        
        }
            .ContactCard__description-information p {
                /* border: 2px solid red; */
                text-align: center;
            }
 }


@media (min-width: 992px) and (max-width: 1199.98px) { 
    .ContactCard__information-container{    
        width:40em;
        flex-direction: column;
        margin-bottom: 3em;
        /* border: 3px solid green; */
    }
    .ContactCard__description-information p {
        text-align: justify;
        margin-bottom: 0;
    }
 }


@media (min-width: 1200px) { 
    .ContactCard__information-container{
        /* border: 6px solid rgb(0, 0, 0); */
        max-width: 90%;
        flex-direction: row;
        margin-bottom: 2em;
    }
    .ContactCard__contact-information{
        /* border: 3px solid red; */
        max-width: 60%;
    }
    .ContactCard__description-information{
        /* border: 3px solid green; */
        max-width: 40%;
    }
    .ContactCard__description-information p {
        text-align: justify;
        margin-bottom: 0;
    }
 }